// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pages-car-insurance-application-js": () => import("./../../../src/pages/pages/car-insurance-application.js" /* webpackChunkName: "component---src-pages-pages-car-insurance-application-js" */),
  "component---src-pages-pages-car-insurance-discounts-js": () => import("./../../../src/pages/pages/car-insurance-discounts.js" /* webpackChunkName: "component---src-pages-pages-car-insurance-discounts-js" */),
  "component---src-pages-pages-contact-js": () => import("./../../../src/pages/pages/contact.js" /* webpackChunkName: "component---src-pages-pages-contact-js" */),
  "component---src-pages-pages-pip-insurance-js": () => import("./../../../src/pages/pages/pip-insurance.js" /* webpackChunkName: "component---src-pages-pages-pip-insurance-js" */),
  "component---src-pages-pages-privacy-js": () => import("./../../../src/pages/pages/privacy.js" /* webpackChunkName: "component---src-pages-pages-privacy-js" */),
  "component---src-pages-pages-sr-22-fr-44-insurance-js": () => import("./../../../src/pages/pages/sr-22-fr-44-insurance.js" /* webpackChunkName: "component---src-pages-pages-sr-22-fr-44-insurance-js" */),
  "component---src-pages-pages-terms-js": () => import("./../../../src/pages/pages/terms.js" /* webpackChunkName: "component---src-pages-pages-terms-js" */),
  "component---src-templates-index-template-js": () => import("./../../../src/templates/index.template.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-quotes-template-js": () => import("./../../../src/templates/quotes.template.js" /* webpackChunkName: "component---src-templates-quotes-template-js" */),
  "component---src-templates-rates-template-js": () => import("./../../../src/templates/rates.template.js" /* webpackChunkName: "component---src-templates-rates-template-js" */),
  "component---src-templates-thanks-template-js": () => import("./../../../src/templates/thanks.template.js" /* webpackChunkName: "component---src-templates-thanks-template-js" */)
}

